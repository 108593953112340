import Analytics from '@components/Analytics/Analytics';
import SEO from '@components/Analytics/SEO';
import { ErrorBoundary, ToastContainer } from '@components/index';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { DehydratedState, HydrationBoundary } from '@tanstack/react-query';
import WithAxios from 'WithAxios';
import { AnalyticsProvider, PartnerProvider } from 'contexts';
import { CustomQueryClientProvider } from 'contexts/CustomQueryClientProvider';
import { useThemePreference } from 'hooks/theme';
import { ldProvider } from 'launchDarklyProvider.config';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { NextPage } from 'next';
import { appWithTranslation } from 'next-i18next';
import type { AppProps as NextAppProps } from 'next/app';
import { ComponentType, ReactNode } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import '../styles/antd.less';
import GlobalStyles from '../styles/globalStyles';
import DataDogInit from '@components/Analytics/DataDogInit';
import { futuraPt, tiempos } from 'styles/fonts';
import AmplitudeInit from '@components/Analytics/AmplitudeInit';
import { MaintenanceToast } from '@components/common';

config.autoAddCss = false;

type GetLayout = (page: ReactNode) => ReactNode;

type Page<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & { getLayout?: GetLayout };

type AppProps<P = Record<string, unknown>> = NextAppProps<P> & { Component: Page<P> };

function App({ Component, pageProps }: AppProps<{ host: string; dehydratedState: DehydratedState }>) {
  const getLayout = Component.getLayout || ((page) => page);
  const theme = useThemePreference(pageProps?.host);

  return (
    <>
      <style jsx global>{
        // used to override antd popovers
        `
          html {
            .ant-picker-header-view,
            .ant-picker-cell-inner {
              font-family: ${futuraPt.style.fontFamily};
            }
          }
        `
      }</style>
      <Analytics />
      <SEO />
      <DataDogInit />
      <AmplitudeInit />
      <main className={`${tiempos.variable} ${futuraPt.variable}`}>
        <ThemeProvider theme={theme}>
          <CustomQueryClientProvider>
            <PartnerProvider host={pageProps.host}>
              <AnalyticsProvider>
                <GlobalStyles />
                <ToastContainer limit={1} />
                <WithAxios>
                  <HydrationBoundary state={pageProps.dehydratedState}>
                    <ErrorBoundary>
                      <MaintenanceToast />
                      {getLayout(<Component {...pageProps} />)}
                    </ErrorBoundary>
                  </HydrationBoundary>
                </WithAxios>
              </AnalyticsProvider>
            </PartnerProvider>
          </CustomQueryClientProvider>
        </ThemeProvider>
      </main>
    </>
  );
}

export default withLDProvider(ldProvider)(appWithTranslation<never>(App) as ComponentType);
